<template>
    <div class="exception-body access-page">
        <div class="exception-top">
            <img src="layout/images/icon-access.png" alt="poseidon-layout" />
        </div>

        <div class="exception-bottom">
            <div class="exception-wrapper">
                <span class="exception-summary">Access Denied</span>
                <span class="exception-detail">You Don't Have Permissions To Access This Resource</span>
                <router-link to="/">
                    <Button label="Dashboard" icon="pi pi-home"></Button>
                </router-link>
                <router-link to="/" class="logo">
                    <img src="layout/images/logo.svg" alt="poseidon-layout" class="logo-icon"  />
                </router-link>
                <span class="exception-footer">PrimeVue Poseidon</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
